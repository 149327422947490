const env = {
  SERVER_BASE_URL: 'https://dev.admin.getandbuy.kz',
}

console.log = () => {};

/* eslint-disable @typescript-eslint/no-explicit-any */
const findFirstExistingProperty = (properties, errorMessage) => {
    const notEmptyProperties = properties.filter((property) => property !== undefined && property !== '');
    if (notEmptyProperties.length === 0) {
      throw new Error(errorMessage);
    }
    return notEmptyProperties[0];
  };
  
const resolveDynamicEnv = (envName) => findFirstExistingProperty(
  [(window).env[envName], process.env[envName]],
  'Failed to resolve dynamic name...',
);

var _BASE_URL = "http://185.192.108.162:18000";
if (!env.SERVER_BASE_URL.includes("SERVER_BASE_URL")){
  _BASE_URL = env.SERVER_BASE_URL;
}
export const SERVER_BASE_URL = _BASE_URL;
  